import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getFullStructureName } from './structureUtils';


const BeatSectionEditor = ({ onClose, onApplyChanges, beatStructure, editMode, setLoadingState, loading, onApplyStart }) => {
    const drumElements = ['808s', 'Kicks', 'Claps', 'Snares', 'Hihats', 'Open Hats', 'Percs', 'Cymbals'];
    const [selectedDrums, setSelectedDrums] = useState({});
    const [removeSelections, setRemoveSelections] = useState({});
    const [globalSelections, setGlobalSelections] = useState({});

    const toggleRemove = (section, drum) => {
        const key = `${section}-${drum}`;
        setRemoveSelections(prev => ({ ...prev, [key]: !prev[key] }));
    };
    
    const toggleGlobal = (section, drum) => {
        setGlobalSelections(prev => {
            const updatedSelections = { ...prev };
    
            // Iterate over all keys and reset selections for the same drum type in different sections
            Object.keys(updatedSelections).forEach(key => {
                if (key.endsWith(`-${drum}`) && key !== `${section}-${drum}`) {
                    updatedSelections[key] = false;
                }
            });
    
            // Toggle the current selection
            const currentKey = `${section}-${drum}`;
            updatedSelections[currentKey] = !prev[currentKey];
    
            return updatedSelections;
        });
    };
    
    
    

    const { getIdTokenClaims } = useAuth0();

    useEffect(() => {
        // Check if beatStructure has elements
        if (beatStructure && beatStructure.length > 0) {
            // Initialize selectedDrums state based on the beat structure
            const initialDrumsState = {};
            beatStructure.forEach(section => {
                initialDrumsState[section] = drumElements.reduce((acc, drum) => {
                    acc[drum] = false; // Initialize all drum elements as unchecked
                    return acc;
                }, {});
            });
            setSelectedDrums(initialDrumsState);
        } else {
            // Log an error or handle the case when beatStructure is empty or not as expected
            console.error("BeatSectionEditor: No beat structure provided or it's in an incorrect format", beatStructure);
        }
    }, [beatStructure]);

    const handleCheckboxChange = (section, drumElement) => {
        setSelectedDrums(prevSelectedDrums => ({
            ...prevSelectedDrums,
            [section]: {
                ...prevSelectedDrums[section],
                [drumElement]: !prevSelectedDrums[section][drumElement] // Toggle the state
            }
        }));
    };

    //Swap Drum of Section
    const handleSwapDrumsClick = async () => {
        //setLoadingState(false);
        onClose(); // Close the editor after applying changes
        const idTokenJson = await getIdTokenClaims();
        const idToken = idTokenJson.__raw;
        // Call the backend endpoint with selected drums for each section
        console.log("Sending selected drums to backend:", JSON.stringify(selectedDrums));

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/change_stems`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`,
                },
                credentials: 'include',
                body: JSON.stringify(selectedDrums)
                
            });
                console.log("Sending selected drums to backend:", JSON.stringify(selectedDrums));

            const responseData = await response.json();
            if (responseData.new_audio_url) {
                onApplyChanges(selectedDrums, responseData.new_audio_url);
            }
        } catch (error) {
            console.error('Error changing stems:', error);
        }
    };

    // Remove Drum From Section
    const handleRemoveDrumsClick = async () => {
        onClose(); // Close the editor after applying changes
        const idTokenJson = await getIdTokenClaims();
        const idToken = idTokenJson.__raw;
    
        // Prepare the data to send to the backend
        const drumsToRemove = Object.keys(removeSelections).reduce((acc, key) => {
            if (removeSelections[key]) {
                const [section, drum] = key.split('-');
                if (!acc[section]) acc[section] = [];
                acc[section].push(drum);
            }
            return acc;
        }, {});
    
        console.log("Removing selected drums:", JSON.stringify(drumsToRemove));
    
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/remove_drum`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`,
                },
                credentials: 'include',
                body: JSON.stringify(drumsToRemove)
            });
    
            const responseData = await response.json();
            if (responseData.new_audio_url) {
                onApplyChanges(drumsToRemove, responseData.new_audio_url);
            }
        } catch (error) {
            console.error('Error removing drums:', error);
        }
    };
    

    // Set Global Drum From Section
    const handleSetGlobalDrumsClick = async () => {
        onClose(); // Close the editor after applying changes

        const idTokenJson = await getIdTokenClaims();
        const idToken = idTokenJson.__raw;

        // Transform globalSelections to the required format
        const transformedGlobalSelections = beatStructure.reduce((acc, section) => {
            acc[section] = drumElements.reduce((drumAcc, drum) => {
                drumAcc[drum] = globalSelections[`${section}-${drum}`];
                return drumAcc;
            }, {});
            return acc;
        }, {});

        console.log("Sending transformed global drum selections to backend:", JSON.stringify(transformedGlobalSelections));

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/set_global_drum`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`,
                },
                credentials: 'include',
                body: JSON.stringify(transformedGlobalSelections) // Corrected to send transformedGlobalSelections
            });

            console.log("Sending transformed global drum selections to backend:", JSON.stringify(transformedGlobalSelections));

            const responseData = await response.json();
            if (responseData.new_audio_url) {
                onApplyChanges(transformedGlobalSelections, responseData.new_audio_url);
            }
        } catch (error) {
            console.error('Error changing stems:', error);
        }
    };

    const handleApplyChangesClick = async () => {
        onApplyStart(); // This should be called at the start of the function
        setLoadingState(true);
        try {
            switch (editMode) {
                case 'swap':
                    await handleSwapDrumsClick();
                    break;
                case 'remove':
                    await handleRemoveDrumsClick();
                    break;
                case 'global':
                    await handleSetGlobalDrumsClick();
                    break;
                default:
                    console.error('Invalid edit mode');
            }
        } catch (error) {
            console.error('Error applying changes:', error);
        } finally {
            console.log("Applying changes and closing editor");
            setLoadingState(false);
            //onClose(); // Close the editor after applying changes
        }
    };
    
    


    return (
    // If you want to title you can here with H3 (in the div class)

    // Beat Editing Section
    <div className="beat-section-editor">
<div className="editor-buttons flex space-x-2">
  <button
    className="editor-button bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
    onClick={onClose}
  >
    Cancel
  </button>
  <button
    className={`editor-button ${loading ? 'bg-gray-400 text-gray-700 cursor-not-allowed' : 'bg-blue-500 text-white hover:bg-blue-600'} px-4 py-2 rounded-md`}
    onClick={handleApplyChangesClick}
    disabled={loading}
  >
    Apply Changes
  </button>
</div>

{/* Swap Drum To Section */}

<div className="mt-4 flex justify-center"> {/* Center the content */}
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"> {/* Responsive grid */}
    {editMode === 'swap' &&
      beatStructure.map(section => (
        <div key={section} className="card p-4 bg-base-100 shadow-xl"> 
          <h4 className="text-lg font-semibold mb-2">Swap Drum From {getFullStructureName(section)} Section:</h4>
          <div className="flex flex-col space-y-2">
            {drumElements.map(drum => (
              <div key={drum} className="flex items-center">
                <input 
                  type="checkbox" 
                  id={`${section}-${drum}`}
                  checked={selectedDrums[section] && selectedDrums[section][drum]}
                  onChange={() => handleCheckboxChange(section, drum)}
                  className="checkbox checkbox-primary" 
                />
                <label htmlFor={`${section}-${drum}`} className="ml-2">{drum}</label>
              </div>
            ))}
          </div>
        </div>
      ))}
  </div>
</div>

{/* Remove Drum From Section */}
{editMode === 'remove' && (
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
    {beatStructure.map((section) => (
      <div key={section} className="bg-gray-200 p-5 rounded-lg shadow-lg">
        <h4 className="text-lg font-bold mb-2">Remove Drum From {getFullStructureName(section)} Section:</h4>
        <div className="flex-1 flex flex-col gap-4">
          {drumElements.map((drum) => (
            <div key={drum} className="flex items-center">
              <button
                type="button"
                className={`${removeSelections[`${section}-${drum}`] ? 'bg-red-500 text-white' : 'bg-gray-200' } px-4 py-2 rounded-md flex items-center`}
                onClick={() => toggleRemove(section, drum)}
              >
                {removeSelections[`${section}-${drum}`] && <span className="mr-2">❌</span>}
                <span className={`${removeSelections[`${section}-${drum}`] ? 'line-through' : ''}`}>
                  {drum}
                </span>
              </button>
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>
)}


{/* Set Global Drum From Section */}
{editMode === 'global' && (
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
    {beatStructure.map((section) => (
      <div key={section} className="bg-gray-200 p-5 rounded-lg shadow-lg">
        <h4 className="text-lg font-bold mb-2">Set Global Drum For {getFullStructureName(section)} Section:</h4>
        <div className="flex-1 flex flex-col gap-4">
          {drumElements.map((drum) => (
            <div key={drum} className="flex items-center">
              <button
                type="button"
                className={`${globalSelections[`${section}-${drum}`] ? 'bg-green-500 text-white' : 'bg-gray-200' } px-4 py-2 rounded-md flex items-center`}
                onClick={() => toggleGlobal(section, drum)}
              >
                {globalSelections[`${section}-${drum}`] && <span className="mr-2">✔️</span>}
                {drum}
              </button>
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>
)}


            
                </div>
                    );
        };

export default BeatSectionEditor;