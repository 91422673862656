import React from 'react';

const Licenses = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center text-white mb-8">AI System Licenses and Training Information</h1>

      <div className="bg-base-200 rounded-lg shadow-lg p-6 mb-8">
        <h2 className="text-2xl font-semibold mb-4">Training Data Information</h2>
        <p className="mb-4">
          <span className="text-blue-500 font-bold">Data Sources:</span> 
          <span className="text-black"> All music data is uploaded by our users and is used to train our models. We utilize musical data uploaded by our users to train our proprietary models. Additional musical information and spectrasonical data is utilized to improve generations. Users are responsible for the content of their musical data and YSM.ai is not liable for any damages resulting from the use of their data. Users are also responsible for ensuring that their data is accurate and up-to-date. Users have complete control over their data and can delete or modify their data at any time. All uploaded data is stored securely and is not shared with third parties, unless otherwise explicitly stated. Third party usage will alway be on an "opt-in" basis, we will never force users to automatically share their data with third parties unless if they explicitly agree to do so. Uploaded content is attributed to the user who uploaded it, persisting the ownership of the data as well as licensable rights to the data. In case of publishing or master recording auditing we have the right to audit the data and work with the rights holders to ensure that their data is accurate and not stolen. </span>
        </p>
        <p className="mb-4">
          <span className=" text-blue-500 font-bold">Data Usage Rights:</span> 
          <span className="text-black"> All usage rights of generations are specified by the content contributors. Rights holders set the usage rights of their data. We offer baseline usage rights for all data uploaded by users, however, users can change these settings at any time, and settings are persisted throughout all of the content uploaded by the user. We offer restrictive clauses for rights holders whom have strict policies, to ensure their policies are always respected and represented automatically. Songwriters and vocalists are able to set their own explicit content thresholds, allowing or preventing the potential of hateful content, or explicit content. By uploading through our platform, we are not responsible for the content of the data uploaded by the user, nor are we responsible for any damages resulting from the use of their data. </span>
        </p>
        <p>
          <span className="text-blue-500 font-bold">Data Processing:</span> 
          <span className="text-black"> Data is cleaned and vetted through music and rights holders agencies via content ID watermarking systems. Once the data is vetted, it is then processed through our proprietary models. We take metadata inputs users and contributors submit to enhance model accuracy. Music information and labelling is stored in our database systems linking to contributors and users whom generate with the content. All uploaded data goes through a pipeline to be successfully retrievable by our models and systems for generations. </span>
        </p>
      </div>

      <div className="bg-base-200 rounded-lg shadow-lg p-6 mb-8">
        <h2 className="text-2xl font-semibold mb-4">Compliance and Ethical Considerations</h2>
        <p className="mb-4">
          <span className=" text-blue-500 font-bold">Privacy Compliance:</span> 
          <span className="text-black"> Please review our <a href="https://www.ysm.ai/terms-conditions" className="text-secondary hover:underline">privacy policy</a> for more information on how we handle user data.</span>
        </p>
        <p className="mb-4">
          <span className=" text-blue-500 font-bold">Ethical AI Guidelines:</span> 
          <span className="text-black"> All of our data is collected and processed in accordance with the <a href="https://www.ysm.ai/terms-conditions" className="text-secondary hover:underline">YSM.AI Terms and Conditions</a>. We are committed to ensuring that our AI systems are developed and used in an ethical and responsible manner. We strive to maintain transparency and accountability in our development and use of AI systems. None of our data is shared with third parties without the explicit consent of the user. None of our musical data is scrapped from the internet, all data is uploaded by the rights holders. Rights holders are responsible for setting the terms and conditions of their data use. We do not collect or store any personally identifiable information (PII) or sensitive data.</span>
        </p>
      </div>

      <div className="bg-base-200 rounded-lg shadow-lg p-6 mb-8">
        <h2 className="text-2xl font-semibold mb-4">Proprietary Software</h2>
        <ul className="list-disc pl-5 space-y-2">
          <span className="text-blue-500 font-bold">Text-To-Beat Generation: </span><li> 
              <span className="text-black">Our proprietary model handling the generation of beats from textual input. We utilize musical data uploaded by our users to train this model. Model is specifically handling and designed to be trained upon the textual meta data of the musical data uploaded by the user. Additional musical information and spectrasonical data is utilized to improve generations. Reinforced learning and human feedback systems are implemented to anonomously record interactions with generations to improve generations. </span></li> 
          <span className='text-blue-500 font-bold'>Text-To-Lyrics Generation: </span>
            <li><span className="text-black">Work-in-Progress... Coming soon</span></li>
          <span className='text-blue-500 font-bold'>Lyrics-To-Singing Generation: </span>
            <li> <span className="text-black">Work-in-Progress... Coming soon</span></li> 
          {/* Add more proprietary software as needed */}
        </ul>
      </div>
      
      <div className="bg-base-200 rounded-lg shadow-lg p-6 mb-8">
        <h2 className="text-2xl font-semibold mb-4">Model Development Tools and Libraries</h2>
        <ul className="list-disc pl-5 space-y-2">
          <li>TensorFlow & PyTorch: <span className="text-black">The development and training tools utilized to build our specific models, while also applicable inference tools for the models.</span></li>
          <li>Scikit-learn: <span className="text-black">Mathematical and statistical modeling and machine learning algorithms for data analysis and prediction.</span></li>
          <li>Word2Vec: <span className="text-black">Library for word embedding models, which are used to represent words as vectors for classification and matching purposes.</span></li>
          {/* Add more open source libraries as needed */}
        </ul>
      </div>

    </div>
  );
};

export default Licenses;