import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';

const RightsPopup = ({ isOpen, onClose, leaseOptions, onPurchase }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="modal-box">
        <h3 className="font-bold text-lg mb-4">Purchase Rights</h3>
        <div className="space-y-4">
          {leaseOptions.map((option, index) => (
            <div key={index} className="card bg-base-100 shadow-xl">
              <div className="card-body">
                <h4 className="card-title">{option.title}</h4>
                <p>{option.details}</p>
                <button className="btn btn-primary" onClick={() => onPurchase(option.price_id)}>
                  <FontAwesomeIcon icon={faShoppingCart} className="mr-2" /> Buy
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className="modal-action mt-4">
          <button className="btn btn-primary" onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default RightsPopup;
