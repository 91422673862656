import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './LearnMoreAccordion.css'; // Ensure this CSS file is linked

const LearnMoreAccordion = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`learn-more-accordion ${isOpen ? 'open' : ''}`} onClick={toggleAccordion}>
            {!isOpen ? (
                <div className="learn-more-closed">
                    <span className="question-mark-icon">?</span> Learn More
                </div>
            ) : (
                <div className="learn-more-open">
                    <ul>
                        <li><Link to="/subscription-features">Subscription Features</Link></li>
                        <li><Link to="/beat-editing">How Beat Editing Works</Link></li>
                        <li><Link to="/rights-usage">Rights & Usage of Music</Link></li>
                        <li><Link to="/ysm-licenses">Licenses & Training Information</Link></li>
                        <li><Link to="/about">About our team</Link></li>
                        <li><Link to="/terms-conditions">Terms & Conditions</Link></li>

                    </ul>
                </div>
            )}
        </div>
    );
};

export default LearnMoreAccordion;
