import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import BannerPage from '../signups/Banner'; // Assuming your Banner component is named BannerPage
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
  const { loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  const handleLoginClick = () => {
    loginWithRedirect();
  };

  const handleDatasetClick = () => {
    navigate('/data-set');
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-900 text-white">
      {/* Header */}
      <header className="flex flex-col items-center justify-center text-center py-16 bg-transparent">
        <h1 className="text-6xl font-bold mb-4">YSM - Your Studio Maestro</h1>
        <p className="text-2xl mt-4 max-w-2xl mx-auto">
          The future of music isn't just listening, it's co-creating and earning with artists and producers.
        </p>
        <button
          className="mt-6 px-8 py-3 bg-blue-600 text-white rounded-lg text-lg font-semibold  hover:bg-blue-700 hover:scale-105 transition-transform duration-450"
          onClick={handleLoginClick}
        >
          Sign-up / Login To Use
        </button>
      </header>

      {/* Banner Section */}
      <section className="container mx-auto mb-12">
        <BannerPage /> {/* Integrate the BannerPage component here */}
      </section>

      {/* Three Panels */}
      <section className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 mb-12 px-6">
        <div className="bg-gradient-to-r from-indigo-500 to-purple-600 p-8 rounded-lg shadow-md flex flex-col items-center text-white hover:scale-105 transition-transform duration-300">
          <h2 className="text-2xl font-semibold mb-4">Generate Beats</h2>
          <p className="text-center">Craft unique rhythms and melodies with ease.</p>
          <p className="text-center">Generate beats with your favorite producers' data.</p>
          <p className="mt-4 text-center"><span className="text-green-400">✔️</span> Currently Available</p>
        </div>
        <div className="bg-gradient-to-r from-teal-500 to-blue-600 p-8 rounded-lg shadow-md flex flex-col items-center text-white hover:scale-105 transition-transform duration-300">
          <h2 className="text-2xl font-semibold mb-4">Generate Lyrics</h2>
          <p className="text-center">Find the perfect words to express your emotions.</p>
          <p className="text-center">Generate lyrics with your favorite songwriters' data.</p>
          <p className="mt-4 text-center"><span className="text-red-400">❌</span> Coming Soon</p>
        </div>
        <div className="bg-gradient-to-r from-yellow-500 to-orange-600 p-8 rounded-lg shadow-md flex flex-col items-center text-white hover:scale-105 transition-transform duration-300">
          <h2 className="text-2xl font-semibold mb-4">Generate Voice</h2>
          <p className="text-center">Bring your music to life with AI-powered vocals.</p>
          <p className="text-center">Generate songs with your favorite artists' data.</p>
          <p className="mt-4 text-center"><span className="text-red-400">❌</span> Coming Soon</p>
        </div>
      </section>
      <section className="container mx-auto mb-12 px-6">
  <div className="bg-gradient-to-r from-green-400 to-blue-500 p-8 rounded-lg shadow-md flex flex-col items-center text-white hover:scale-105 transition-transform duration-300">
    <h2 className="text-2xl font-semibold mb-4">Our Dataset</h2>
    <p className="text-center">Look inside our current dataset to see what's possible with our platform.</p> 
    <button className="mt-4 py-2 px-6 bg-blue-600 text-white rounded-lg hover:bg-blue-700" onClick={handleDatasetClick}>
      Explore Dataset
    </button>          
  </div>       
</section>

    </div>
  );
};

export default LandingPage;
