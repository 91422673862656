import { displayError, displaySuccess } from './utils';

const Notifications = ({
  errorMessages,
  successMessages,
  setErrorMessages,
  setSuccessMessages,
}) => {
  return (
    <div>
      {errorMessages && <div className="errmsg">{errorMessages}</div>}
      {successMessages && <div className="successmsg">{successMessages}</div>}
    </div>
  );
};

export default Notifications;