import React from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';

const Layout = ({ isAuthenticated, tokensRemaining, showDropdown, setShowDropdown, handleLogout, handleLoginClick }) => {
  const location = useLocation();

  return (
    <div>
      {location.pathname !== '/welcome' && (
        <header>
          <h2><Link to='/' className="header-link">YSM.AI</Link></h2>
          <div className="header-left">
            <div className="tokens-remaining">
              {isAuthenticated && (
                <>
                  <span>Tokens Remaining: {tokensRemaining}</span>
                </>
              )}
            </div>
          </div>
          <div className="account-dropdown">
            <button onClick={() => setShowDropdown(!showDropdown)}>Account</button>
            {showDropdown && (
              <div className="dropdown-content">
                {isAuthenticated ? (
                  <>
                    <a href="/" onClick={(e) => { e.preventDefault(); handleLogout(); }}>Logout</a>
                    <Link to="/account/profile">My Account</Link>
                    <Link to="/subscriptions">Subscriptions</Link>
                    <Link to="/choice">Contribute And Earn</Link>
                  </>
                ) : (
                  <>
                    <a href="/" onClick={(e) => { e.preventDefault(); handleLoginClick(); }}>Login/Register</a>
                  </>
                )}
              </div>
            )}
          </div>
        </header>
      )}
      <Outlet />
    </div>
  );
};

export default Layout;
