// StandardLeasePopup.js
import React from 'react';

export const StandardLeasePopup = ({ contractData, onContractChange, onClose, onSave  }) => {
    const handleChange = (event) => {
      const updatedData = {
        ...contractData,
        [event.target.name]: event.target.value,
      };
      onContractChange(updatedData);
    };
  

  return (
    <div className="popup">
      <div className="popup-content">
        <h2>Standard Lease Terms</h2>
        {/* Render form fields for Standard Lease */}
        <div className="form-group">
          <label htmlFor="term_years">Term of Years for the License:</label>
          <select
            id="term_years"
            name="term_years"
            value={contractData.term_years}
            onChange={handleChange}
            className="form-control"
            onClick={(event) => event.stopPropagation()}
          >
            <option value="1">1</option>
            <option value="3">3</option>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="25">25</option>
            <option value="Unlimited">Unlimited</option>
          </select>
          <label htmlFor="distribution_copies">Number of Distribution Copies allowed</label>
          <select
            id="distribution_copies"
            name="distribution_copies"
            value={contractData.distribution_copies}
            onChange={handleChange}
            className="form-control"
            onClick={(event) => event.stopPropagation()}
          >
            <option value="1000">1,000</option>
            <option value="5000">5,000</option>
            <option value="100000">100,000</option>
            <option value="250000">250,000</option>
            <option value="500000">500,000</option>
            <option value="1000000">1,000,000</option>
            <option value="5000000">5,000,000</option>
            <option value="Unlimited">Unlimited</option>
          </select>
          <label htmlFor="audio_streams">Number of Audio Streams allowed</label>
          <select
            id="audio_streams"
            name="audio_streams"
            value={contractData.audio_streams}
            onChange={handleChange}
            className="form-control"
            onClick={(event) => event.stopPropagation()}
          >
            <option value="5000">5,000</option>
            <option value="10000">10,000</option>
            <option value="100000">100,000</option>
            <option value="250000">250,000</option>
            <option value="500000">500,000</option>
            <option value="1000000">1,000,000</option>
            <option value="5000000">5,000,000</option>
            <option value="Unlimited">Unlimited</option>
          </select>
          <label htmlFor="free_downloads">Number of Free Downloads Allowed</label>
          <select
            id="free_downloads"
            name="free_downloads"
            value={contractData.free_downloads}
            onChange={handleChange}
            className="form-control"
            onClick={(event) => event.stopPropagation()}
          >
            <option value="1000">1,000</option>
            <option value="5000">5,000</option>
            <option value="10000">10,000</option>
            <option value="100000">100,000</option>
            <option value="1000000">1,000,000</option>
            <option value="Unlimited">Unlimited</option>
          </select>
          <label htmlFor="mv_streams">Number of Monetized "Music Video" Streams Allowed</label>
          <select
            id="mv_streams"
            name="mv_streams"
            value={contractData.mv_streams}
            onChange={handleChange}
            className="form-control"
            onClick={(event) => event.stopPropagation()}
          >
            <option value="5000">5,000</option>
            <option value="10000">10,000</option>
            <option value="100000">100,000</option>
            <option value="500000">500,000</option>
            <option value="1000000">1,000,000</option>
            <option value="5000000">5,000,000</option>
            <option value="Unlimited">Unlimited</option>
          </select>
          <label htmlFor="vid_streams">Number of Monetized Video Streams Allowed</label>
          <select
            id="vid_streams"
            name="vid_streams"
            value={contractData.vid_streams}
            onChange={handleChange}
            className="form-control"
            onClick={(event) => event.stopPropagation()}
          >
            <option value="5000">5,000</option>
            <option value="10000">10,000</option>
            <option value="100000">100,000</option>
            <option value="500000">500,000</option>
            <option value="1000000">1,000,000</option>
            <option value="5000000">5,000,000</option>
            <option value="Unlimited">Unlimited</option>
          </select>
          <label htmlFor="nonmonmv_streams">Number of Non-Monetized "Music Video" Streams Allowed</label>
          <select
            id="nonmonmv_streams"
            name="nonmonmv_streams"
            value={contractData.nonmonmv_streams}
            onChange={handleChange}
            className="form-control"
            onClick={(event) => event.stopPropagation()}
          >
            <option value="5000">5,000</option>
            <option value="10000">10,000</option>
            <option value="100000">100,000</option>
            <option value="500000">500,000</option>
            <option value="1000000">1,000,000</option>
            <option value="5000000">5,000,000</option>
            <option value="Unlimited">Unlimited</option>
          </select>
        <label htmlFor="nonmonvid_streams">Number of Non-Monetized Video Streams Allowed</label>
          <select
            id="nonmonvid_streams"
            name="nonmonvid_streams"
            value={contractData.nonmonvid_streams}
            onChange={handleChange}
            className="form-control"
            onClick={(event) => event.stopPropagation()}
          >
            <option value="5000">5,000</option>
            <option value="10000">10,000</option>
            <option value="100000">100,000</option>
            <option value="500000">500,000</option>
            <option value="1000000">1,000,000</option>
            <option value="5000000">5,000,000</option>
            <option value="Unlimited">Unlimited</option>
          </select>
          <label htmlFor="Broadcast_rights">Radio Broadcasting Rights</label>
            <select
                id="Broadcast_rights"
                name="Broadcast_rights"
                value={contractData.Broadcast_rights}
                onChange={handleChange}
                className="form-control"
  onClick={(event) => event.stopPropagation()}
                >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select>
            <label htmlFor="Performance_rights">Allow performances for profit</label>
            <select
                id="Performance_rights"
                name="Performance_rights"
                value={contractData.Performance_rights}
                onChange={handleChange}
                className="form-control"
  onClick={(event) => event.stopPropagation()}
                >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select>
            <label htmlFor="Nonprofit_performances">Allow Nonprofit performances</label>
            <select
                id="Nonprofit_performances"
                name="Nonprofit_performances"
                value={contractData.Nonprofit_performances}
                onChange={handleChange}
                className="form-control"
  onClick={(event) => event.stopPropagation()}
                >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select>
            <label htmlFor="Synchronization_rights">Synchronization rights</label>
            <select
                id="Synchronization_rights"
                name="Synchronization_rights"
                value={contractData.Synchronization_rights}
                onChange={handleChange}
                className="form-control"
  onClick={(event) => event.stopPropagation()}
                >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select>
        <label htmlFor="comments">Additional Comments:</label>
        <textarea
          id="comments"
          name="comments"
          value={contractData.comments}
          onChange={handleChange}
          className="form-control"
            onClick={(event) => event.stopPropagation()}
        />

        </div>
        <button className="btn btn-primary" onClick={onSave}>
          Save
        </button>
        <button className="btn btn-secondary" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default StandardLeasePopup;