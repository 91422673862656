import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authFetch } from '../authFetch';
import { useAuth0 } from '@auth0/auth0-react';
import AddTokens from '../components/adminTools/addTokens';

// Import icons from Lucide React
import { BarChart, Users, CreditCard, Music } from "lucide-react";

const AdminPortal = () => {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    //loading state
    const [showLoadingOverlay, setShowLoadingOverlay] = useState(true); // Start with loading

    // Users State
    const [users, setUsers] = useState([]);
    const [usersPage, setUsersPage] = useState(1);
    const [usersTotalPages, setUsersTotalPages] = useState(1);

    // Add Subscription tokens
    const [isAddTokensOpen, setIsAddTokensOpen] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState(null);


    // Subscriptions State
    const [subscriptions, setSubscriptions] = useState([]);
    const [subscriptionsPage, setSubscriptionsPage] = useState(1);
    const [subscriptionsTotalPages, setSubscriptionsTotalPages] = useState(1);

    // Producers State
    const [producers, setProducers] = useState([]);
    const [producersPage, setProducersPage] = useState(1);
    const [producersTotalPages, setProducersTotalPages] = useState(1);

    // Beat Orders State
    const [beatOrders, setBeatOrders] = useState([]);
    const [beatOrdersPage, setBeatOrdersPage] = useState(1);
    const [beatOrdersTotalPages, setBeatOrdersTotalPages] = useState(1);

    // Totals State
    const [totalUsers, setTotalUsers] = useState(0);
    const [totalSubscriptions, setTotalSubscriptions] = useState(0);
    const [totalProducers, setTotalProducers] = useState(0);
    const [totalRevenue, setTotalRevenue] = useState(0);

    // Combine all data fetching into a single async function
    const fetchAllData = async () => {
        try {
            setShowLoadingOverlay(true);
            await Promise.all([
                fetchUsers(usersPage),
                fetchSubscriptions(subscriptionsPage),
                fetchProducers(producersPage),
                fetchBeatOrders(beatOrdersPage),
                fetchTotals(),
                fetchSubscriptionStats()
            ]);
        } catch (error) {
            console.error('Error fetching data:', error);
            if (error.status === 403 || error.status === 401) {
                navigate('/welcome', { replace: true });
            }
        } finally {
            setShowLoadingOverlay(false);
        }
    };
    


    useEffect(() => {
        if (isAuthenticated) {
            fetchAllData();
        }
    }, [isAuthenticated, usersPage, subscriptionsPage, producersPage, beatOrdersPage]);


     // Fetch Functions
     const fetchUsers = async (page) => {
        try {
            const url = `${process.env.REACT_APP_BACKEND_URL}/admin/users?page=${page}`;
            const data = await authFetch(url, {}, getAccessTokenSilently);
            setUsers(data.users);
            setUsersTotalPages(data.pages);
            setUsersPage(data.current_page);
        } catch (error) {
            console.error('Error fetching users:', error);
            if (error.status === 403 || error.status === 401) {
                // Unauthorized, redirect to /welcome
                navigate('/welcome', { replace: true });
            }
        }
    };
    
    const fetchSubscriptions = async (page) => {
        try {
            const url = `${process.env.REACT_APP_BACKEND_URL}/admin/user-subscriptions?page=${page}`;
            const data = await authFetch(url, {}, getAccessTokenSilently);
            setSubscriptions(data.subscriptions);
            setSubscriptionsTotalPages(data.pages);
            setSubscriptionsPage(data.current_page);
        } catch (error) {
            console.error('Error fetching subscriptions:', error);
            if (error.status === 403) {
                navigate('/welcome', { replace: true });
            }
        }
    };

    const fetchProducers = async (page) => {
        try {
            const url = `${process.env.REACT_APP_BACKEND_URL}/admin/producers?page=${page}`;
            const data = await authFetch(url, {}, getAccessTokenSilently);
            setProducers(data.producers);
            setProducersTotalPages(data.pages);
            setProducersPage(data.current_page);
        } catch (error) {
            console.error('Error fetching producers:', error);
            if (error.status === 403) {
                navigate('/welcome', { replace: true });
            }
        }
    };

    const fetchBeatOrders = async (page) => {
        try {
            const url = `${process.env.REACT_APP_BACKEND_URL}/admin/beat-orders?page=${page}`;
            const data = await authFetch(url, {}, getAccessTokenSilently);
            setBeatOrders(data.beat_orders);
            setBeatOrdersTotalPages(data.pages);
            setBeatOrdersPage(data.current_page);
        } catch (error) {
            console.error('Error fetching beat orders:', error);
            if (error.status === 403) {
                navigate('/welcome', { replace: true });
            }
        }
    };

    const fetchTotals = async () => {
        try {
            const [totalUsersData, totalSubscriptionsData, totalProducersData, totalBeatOrdersData] = await Promise.all([
                authFetch(`${process.env.REACT_APP_BACKEND_URL}/admin/total-users`, {}, getAccessTokenSilently),
                authFetch(`${process.env.REACT_APP_BACKEND_URL}/admin/total-subscriptions`, {}, getAccessTokenSilently),
                authFetch(`${process.env.REACT_APP_BACKEND_URL}/admin/total-producers`, {}, getAccessTokenSilently),
                authFetch(`${process.env.REACT_APP_BACKEND_URL}/admin/total-beat-orders`, {}, getAccessTokenSilently)
            ]);
            setTotalUsers(totalUsersData.total_users);
            setTotalSubscriptions(totalSubscriptionsData.total_subscriptions);
            setTotalProducers(totalProducersData.total_producers);
            setTotalRevenue(totalBeatOrdersData.total_revenue);
        } catch (error) {
            console.error('Error fetching totals:', error);
        }
    };
    // Add State Variables
    const [subscriptionStats, setSubscriptionStats] = useState({ counts: {}, revenue: {} });


    // Add Fetch Function for Subscription Stats
    const fetchSubscriptionStats = async () => {
        try {
            const url = `${process.env.REACT_APP_BACKEND_URL}/admin/subscription-stats`;
            const data = await authFetch(url, {}, getAccessTokenSilently);
            setSubscriptionStats(data);
        } catch (error) {
            console.error('Error fetching subscription stats:', error);
        }
    };
    useEffect(() => {
        if (isAuthenticated) {
            fetchAllData();
        }
    }, [isAuthenticated, usersPage, subscriptionsPage, producersPage, beatOrdersPage]);
    
    // Pagination Handlers
    const handleNext = (type) => {
        switch(type) {
            case 'users':
                if (usersPage < usersTotalPages) {
                    setUsersPage(prevPage => prevPage + 1);
                }
                break;
            case 'subscriptions':
                if (subscriptionsPage < subscriptionsTotalPages) {
                    const nextPage = subscriptionsPage + 1;
                    setSubscriptionsPage(nextPage);
                    fetchSubscriptions(nextPage);
                }
                break;
            case 'producers':
                if (producersPage < producersTotalPages) {
                    const nextPage = producersPage + 1;
                    setProducersPage(nextPage);
                    fetchProducers(nextPage);
                }
                break;
            case 'beatOrders':
                if (beatOrdersPage < beatOrdersTotalPages) {
                    const nextPage = beatOrdersPage + 1;
                    setBeatOrdersPage(nextPage);
                    fetchBeatOrders(nextPage);
                }
                break;
            default:
                break;
        }
    };

    const handlePrevious = (type) => {
        switch(type) {
            case 'users':
                if (usersPage > 1) {
                    const prevPage = usersPage - 1;
                    setUsersPage(prevPage);
                    fetchUsers(prevPage);
                }
                break;
            case 'subscriptions':
                if (subscriptionsPage > 1) {
                    const prevPage = subscriptionsPage - 1;
                    setSubscriptionsPage(prevPage);
                    fetchSubscriptions(prevPage);
                }
                break;
            case 'producers':
                if (producersPage > 1) {
                    const prevPage = producersPage - 1;
                    setProducersPage(prevPage);
                    fetchProducers(prevPage);
                }
                break;
            case 'beatOrders':
                if (beatOrdersPage > 1) {
                    const prevPage = beatOrdersPage - 1;
                    setBeatOrdersPage(prevPage);
                    fetchBeatOrders(prevPage);
                }
                break;
            default:
                break;
        }
    };

    return (
        <>
                            <AddTokens
                    isOpen={isAddTokensOpen}
                    onClose={() => setIsAddTokensOpen(false)}
                    subscription={selectedSubscription}
                    refreshSubscriptions={() => fetchSubscriptions(subscriptionsPage)}
                />
        <div className="p-8">
            <h1 className="text-3xl font-bold mb-6 text-white">Admin Dashboard</h1>

            {/* Summary Cards */}
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-4 mb-6">
                {/* Total Users */}
                <div className="card bg-base-100 shadow">
                    <div className="card-body">
                        <div className="flex flex-row items-center justify-between">
                            <h2 className="card-title text-sm font-medium">Total Users</h2>
                            <Users className="h-6 w-6 text-gray-500" />
                        </div>
                        <p className="text-2xl font-bold">{totalUsers}</p>
                    </div>
                </div>
                {/* Active Subscriptions */}
                <div className="card bg-base-100 shadow">
                    <div className="card-body">
                        <div className="flex flex-row items-center justify-between">
                            <h2 className="card-title text-sm font-medium">Active Subscriptions</h2>
                            <CreditCard className="h-6 w-6 text-gray-500" />
                        </div>
                        <p className="text-2xl font-bold">{totalSubscriptions}</p>
                    </div>
                </div>
                {/* Total Producers */}
                <div className="card bg-base-100 shadow">
                    <div className="card-body">
                        <div className="flex flex-row items-center justify-between">
                            <h2 className="card-title text-sm font-medium">Total Producers</h2>
                            <Music className="h-6 w-6 text-gray-500" />
                        </div>
                        <p className="text-2xl font-bold">{totalProducers}</p>
                    </div>
                </div>
                {/* Total Revenue from Beat Orders */}
                <div className="card bg-base-100 shadow">
                    <div className="card-body">
                        <div className="flex flex-row items-center justify-between">
                            <h2 className="card-title text-sm font-medium">Beat Orders Revenue</h2>
                            <BarChart className="h-6 w-6 text-gray-500" />
                        </div>
                        <p className="text-2xl font-bold">${totalRevenue.toFixed(2)}</p>
                    </div>
                </div>
            </div>
            {/* Subscription Statistics Section */}
            <div className="card bg-base-100 shadow mb-8">
                <div className="card-body">
                    <h2 className="card-title">Subscription Statistics</h2>
                    <div className="overflow-x-auto mt-4">
                        <table className="table w-full">
                            <thead>
                                <tr>
                                    <th>Subscription Type</th>
                                    <th>Count</th>
                                    <th>Total Revenue</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(subscriptionStats.counts).map(type => (
                                    <tr key={type}>
                                        <td>{type}</td>
                                        <td>{subscriptionStats.counts[type]}</td>
                                        <td>${subscriptionStats.revenue[type].toFixed(2)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* Users Section */}
            <div className="card bg-base-100 shadow mb-8">
                <div className="card-body">
                    <h2 className="card-title">Users</h2>
                    <p className="text-sm text-gray-500">Showing all registered users</p>
                    <div className="overflow-x-auto mt-4">
                        <table className="table w-full">
                            <thead>
                                <tr>
                                    <th>Username</th>
                                    <th>Email</th>
                                    <th>User ID</th>
                                    <th>Account Created</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map(user => (
                                    <tr key={user.user_id}>
                                        <td>{user.username}</td>
                                        <td>{user.email}</td>
                                        <td>{user.user_id}</td>
                                        <td>{new Date(user.created_at).toLocaleDateString()}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="flex justify-between mt-4">
                        <button
                            onClick={() => handlePrevious('users')}
                            disabled={usersPage === 1}
                            className="btn btn-primary"
                        >
                            Previous
                        </button>
                        <span>Page {usersPage} of {usersTotalPages}</span>
                        <button
                            onClick={() => handleNext('users')}
                            disabled={usersPage === usersTotalPages}
                            className="btn btn-primary"
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>

            {/* Subscriptions Section */}
            <div className="card bg-base-100 shadow mb-8">
                <div className="card-body">
                    <h2 className="card-title">Recent Subscriptions</h2>
                    <p className="text-sm text-gray-500">Showing recent subscriptions</p>
                    <div className="overflow-x-auto mt-4">
                        <table className="table w-full">
                            <thead>
                                <tr>
                                    <th>Username</th>
                                    <th>Subscription Type</th>
                                    <th>Tokens Remaining</th>
                                </tr>
                            </thead>
                            <tbody>
                            {subscriptions.map(sub => (
                                <tr
                                    key={sub.username}
                                    className="cursor-pointer hover:bg-gray-100"
                                    onClick={() => {
                                        setSelectedSubscription(sub);
                                        setIsAddTokensOpen(true);
                                    }}
                                >
                                    <td>{sub.username}</td>
                                    <td>{sub.subscription_type}</td>
                                    <td>{sub.tokens_remaining}</td>
                                </tr>
                            ))}
                        </tbody>
                        </table>
                    </div>
                    <div className="flex justify-between mt-4">
                        <button
                            onClick={() => handlePrevious('subscriptions')}
                            disabled={subscriptionsPage === 1}
                            className="btn btn-primary"
                        >
                            Previous
                        </button>
                        <span>Page {subscriptionsPage} of {subscriptionsTotalPages}</span>
                        <button
                            onClick={() => handleNext('subscriptions')}
                            disabled={subscriptionsPage === subscriptionsTotalPages}
                            className="btn btn-primary"
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>

            {/* Producers Section */}
            <div className="card bg-base-100 shadow mb-8">
                <div className="card-body">
                    <h2 className="card-title">Producers</h2>
                    <p className="text-sm text-gray-500">Showing producers and their files uploaded</p>
                    <div className="overflow-x-auto mt-4">
                        <table className="table w-full">
                            <thead>
                                <tr>
                                    <th>Username</th>
                                    <th>Producer Name</th>
                                    <th>Files Uploaded</th>
                                </tr>
                            </thead>
                            <tbody>
                                {producers.map(producer => (
                                    <tr key={producer.producer_name}>
                                        <td>{producer.username}</td>
                                        <td>{producer.producer_name}</td>
                                        <td>{producer.files_uploaded}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="flex justify-between mt-4">
                        <button
                            onClick={() => handlePrevious('producers')}
                            disabled={producersPage === 1}
                            className="btn btn-primary"
                        >
                            Previous
                        </button>
                        <span>Page {producersPage} of {producersTotalPages}</span>
                        <button
                            onClick={() => handleNext('producers')}
                            disabled={producersPage === producersTotalPages}
                            className="btn btn-primary"
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>

            {/* Beat Orders Section */}
            <div className="card bg-base-100 shadow mb-8">
                <div className="card-body">
                    <h2 className="card-title">Beat Orders</h2>
                    <p className="text-sm text-gray-500">Showing recent beat orders</p>
                    <div className="overflow-x-auto mt-4">
                        <table className="table w-full">
                            <thead>
                                <tr>
                                    <th>Producers</th>
                                    <th>Customer Email</th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {beatOrders.map(order => (
                                    <tr key={order.customer_email + order.price}>
                                        <td>{order.producers.join(', ')}</td>
                                        <td>{order.customer_email}</td>
                                        <td>${order.price.toFixed(2)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="flex justify-between mt-4">
                        <button
                            onClick={() => handlePrevious('beatOrders')}
                            disabled={beatOrdersPage === 1}
                            className="btn btn-primary"
                        >
                            Previous
                        </button>
                        <span>Page {beatOrdersPage} of {beatOrdersTotalPages}</span>
                        <button
                            onClick={() => handleNext('beatOrders')}
                            disabled={beatOrdersPage === beatOrdersTotalPages}
                            className="btn btn-primary"
                        >
                            Next
                        </button>
                    </div>
                    
                </div>

            </div>
        </div>

            </>
    );
};

export default AdminPortal;
