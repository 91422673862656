import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import Notifications from './components/Notifications';
import { useAuth0 } from '@auth0/auth0-react';
import RightsPopup from './components/RightsPopup'; // Make sure to import the RightsPopup component
import fetchLeaseOptions from './components/fetchLeaseOptions'; // Import the fetchLeaseOptions function
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faRedo, faDownload, faShoppingCart, faCrown, faGem } from '@fortawesome/free-solid-svg-icons';
import { saveAs } from 'file-saver';
import { loadStripe } from '@stripe/stripe-js';
import { displayError, displaySuccess } from './components/utils';


function WaveformPlayer({ beat_id, audio_url, Beatkey, mode, bpm, beatTitle, beatStructureLabels }) {
    const [errorMessages, setErrorMessages] = useState(''); // Initialize error message state

    const { getIdTokenClaims } = useAuth0();
    const waveformRef = useRef(null);
    const wavesurfer = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioReady, setAudioReady] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [totalDuration, setTotalDuration] = useState(0);
    const [userInfo, setUserInfo] = useState({
        fname: '',
        lname: '',
        country: '',
      });
    const [isInfoComplete, setIsInfoComplete] = useState(false);

    const downloadBeat = () => {
        if (!audio_url) {
            alert('No beat available for download.');
            return;
        }
    
        fetch(audio_url)
            .then(response => response.blob())
            .then(blob => {
                // Use file-saver to save the blob as a file
                //IDK if it should be mp3... its technically a WAV file.
                //Set save as to BeatTitle
                saveAs(blob, beatTitle); // You can specify a filename here
            })
            .catch(e => {
                console.error("Download failed", e);
                alert("Failed to download the beat.");
            });
    };

    useEffect(() => {
        // Fetch user info from the backend to check if it's complete
        const fetchUserInfo = async () => {
            try {
                const idTokenClaims = await getIdTokenClaims();
                const idToken = idTokenClaims.__raw;
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get-user-info`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${idToken}`,
                    },
                    credentials: 'include',
                });
                const data = await response.json();
                setUserInfo({
                    fname: data.first_name,
                    lname: data.last_name,
                    country: data.country,
                });
                setIsInfoComplete(data.first_name && data.last_name && data.country);
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        };

        fetchUserInfo();
    }, []);
    // Need to finish this function
    // Purchase Beat
    const [showRightsPopup, setShowRightsPopup] = useState(false);
    const [leaseOptions, setLeaseOptions] = useState([]);


    useEffect(() => {
        const loadLeaseOptions = async () => {
            const options = await fetchLeaseOptions(beat_id);
            setLeaseOptions(options);
        };
        loadLeaseOptions();
    }, [beat_id]);

    
    useEffect(() => {
        wavesurfer.current = WaveSurfer.create({
            container: waveformRef.current,
            waveColor: '#D9DCFF',
            progressColor: '#4353FF',
            cursorColor: '#4353FF',
            barWidth: 4,
            height: 100,
            responsive: true
        });

        wavesurfer.current.load(audio_url);

        // Event listeners
        wavesurfer.current.on('play', () => setIsPlaying(true));
        wavesurfer.current.on('pause', () => setIsPlaying(false));
        wavesurfer.current.on('stop', () => setIsPlaying(false));
        wavesurfer.current.on('ready', () => {
            setAudioReady(true);
            setTotalDuration(wavesurfer.current.getDuration());
        });
        wavesurfer.current.on('audioprocess', (time) => {
            setCurrentTime(time);
        });

        return () => {
            wavesurfer.current.destroy();
        };
    }, [audio_url]);

    const handlePlayPause = () => {
        if (!audioReady) {
            alert('Beat is not ready yet. Wait 1 moment.');
            return;
        }
        if (isPlaying) {
            wavesurfer.current.pause();
        } else {
            wavesurfer.current.play().catch(error => {
                alert("Error playing audio. Please try again.");
                console.error("Error playing audio:", error);
            });
        }
    };
    
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };

    const handleRestart = () => {
        if (wavesurfer.current) {
            wavesurfer.current.seekTo(0); // Seek to the start of the track
            if (isPlaying) {
                wavesurfer.current.play(); // Play if it was already playing
            }
        }
    };


    const handlePurchase = async (priceId) => {
        try {
          const idTokenClaims = await getIdTokenClaims();
          const idToken = idTokenClaims.__raw;
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
            credentials: 'include',
            body: JSON.stringify({
              beat_id: beat_id,
              lease_option_id: priceId,
              first_name: userInfo.fname,
              last_name: userInfo.lname,
              country: userInfo.country,
            }),
          });
          const session = await response.json();
          const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
          stripe.redirectToCheckout({ sessionId: session.id });
        } catch (error) {
          console.error('Error creating checkout session:', error);
        }
      };

      const handleGetLicensingInfo = () => {
        if (isInfoComplete) {
            setShowRightsPopup(true);
        } else {
            displayError(setErrorMessages)('Please complete your profile information to proceed.');
        }
    };

    return (
        
        // Beat Generation Player Info And Buttons
        <div>
                          <Notifications
        errorMessages={errorMessages}
        setErrorMessages={setErrorMessages}
        displayError={displayError}
                />
            <div className="beat-title">
                <p>Generated Beat: {beatTitle}</p>
            </div>
            <div ref={waveformRef}></div>
            
            {/* Time display */}
            <div className="time-display">
                {formatTime(currentTime)} / {formatTime(totalDuration)}
            </div>
            <button className="btn btn-circle btn-outline btn-accent tooltip" data-tip="Restart" onClick={handleRestart} disabled={!audioReady}>
                <FontAwesomeIcon icon={faRedo} size="lg" />
            </button>
            <button className="btn btn-circle btn-ghost tooltip" data-tip="Play" onClick={handlePlayPause} disabled={!audioReady}>
                <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} size="lg" />
            </button>

            {/* Beat info div */}
            <div className="beat-info">
                <p>Key: {Beatkey}</p>
                <p>Mode: {mode}</p>
                <p>BPM: {bpm}</p>
            </div>
            <div className="beat-structure">
                <p>Structure: {beatStructureLabels}</p>
            </div>
            {/* Download beat div */}
            <div>
            {audio_url &&
                <button className="btn btn-sm btn-circle tooltip" data-tip="Download Beat" onClick={downloadBeat}>
  <FontAwesomeIcon icon={faDownload} />
</button>

            }
        </div>

                {/* 
                Purchasing Beat Button 
                */}
      <br></br>
            <button
                className="btn btn-glass tooltip"
                onClick={handleGetLicensingInfo}
                //disabled={!isInfoComplete}
                data-tip={isInfoComplete ? "Purchase the rights to use" : "Please complete your profile in My Account"}
            >
                <FontAwesomeIcon icon={faGem} className="mr-2" /> Get Licensing Info
            </button>
      <RightsPopup
        isOpen={showRightsPopup}
        onClose={() => setShowRightsPopup(false)}
        leaseOptions={leaseOptions}
        onPurchase={handlePurchase}
        userInfo={userInfo}
        setUserInfo={setUserInfo}
        getIdTokenClaims={getIdTokenClaims}
      />
    </div>
    );
}

export default WaveformPlayer;
