import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot
import App from './App';
import { Auth0Provider } from "@auth0/auth0-react";
//new addition for fixing the refresh of new user login


const domain = "login.ysm.ai";
const clientId = "s1WgRsk3Z8eZhNrwPnyGiNT3J3p0i0HV";
const audience = "https://s1WgRsk3Z8eZhNrwPnyGiNT3J3p0i0HV-api";

//New addition for fixing the refresh of new user login

// Identify the root container
const container = document.getElementById('root');
// Create a root
const root = createRoot(container);

// Correctly use the root.render without specifying the container again
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: audience,
        scope: "openid profile email",
        redirect_uri: window.location.origin,
      }}
      useRefreshTokens={true} //newly added
      cacheLocation="localstorage" //newly added
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
);
