import React, { useState } from 'react';
import './components/AboutOurTeam.css'; // Make sure to create and link this CSS file for styling


const teamMembers = [
    {
        name: 'Cory Rose',
        title: 'CEO',
        role: 'Strategic Business & Marketing Leader',
        background: 'Music Industry Expert with 10+ years of professional experience. Worked alongside every major record label. 7x Multi-Platinum Music Producer & Audio Engineer, 1 billion+ streams. Worked alongside artists like Offset, Polo G, Don Toliver, Ryan Trey, Diddy, Bryson Tiller, and countless of others.',
        contributions: 'Industry connections, bridging music and AI technology. Leading the business and marketing strategy for YSM.AI.'
    },
    {
        name: 'Andrew Rapier',
        title: 'CTO',
        role: 'Technical Visionary and AI Expert',
        background: 'Python & Technology Developer. Experienced in music production & audio engineering. Founder and developer of Beat It AT P&P LLC, a music production company servicing 113,000 registered members with an arsenal of 100+ music production products.',
        contributions: 'Technical vision, AI development, and music production expertise. Developing the AI technology that powers YSM.AI. Developing technical strategies to support fans and creators.'
    }
    // Add more team members here
];

// const PopUpMember =[
//     {
//         name: 'Cory Rose',
//         title: 'CEO',
//         role: 'Strategic Business & Marketing Leader',
//         background: 'Music Industry Expert with 10+ years of professional experience. Worked alongside every major record label. 7x Multi-Platinum Music Producer & Audio Engineer, 1 billion+ streams. Worked alongside artists like Offset, Polo G, Don Toliver, Ryan Trey, Diddy, Bryson Tiller, and countless of others.',
//     },
//     {
//         name: 'Andrew Rapier',
//         title: 'CTO',
//         role: 'Technical Visionary and AI Expert',
//         background: 'Python & Technology Developer. Experienced in music production & audio engineering. Founder and developer of Beat It AT P&P LLC, a music production company servicing 113,000 registered members with an arsenal of 100+ music production products.',
//     }
// ];

const MemberDetailsPopup = ({ member, onClose }) => {
    return (
        <div className="popup-background">
            <div className="popup-container">
                <h2>{member.name} - {member.title}</h2>
                <p><strong>Role:</strong> {member.role}</p>
                <p><strong>Background:</strong> {member.background}</p>
                <p><strong>Key Contributions:</strong> {member.contributions}</p>
                
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

const AboutOurTeam = () => {
    const [selectedMember, setSelectedMember] = useState(null);

    const handleMoreDetails = (member) => {
        setSelectedMember(member);
    };
    
    // Company Info
    const companyInfo = {
        meaning: "YSM stands for 'Your Studio Maestro', symbolizing our commitment to preserving music creators' space in the AI revolution, while allowing anyone to create the tracks that they'd want to hear and generate. At YSM, you are the maestro of your studio, and we are here to help you bring your musical vision to life - connecting you with the best artists, producers and songwriters. Our dataset includes Grammy Award winning artists, producers, and songwriters, and we are constantly updating our dataset to include the latest and greatest in the music industry. We are the first AI company to offer a music production platform that is powered by the music industry's top professionals. Our mission is to democratize music production and make it accessible to everyone. We are the future of music production. We are YSM.AI.",
        location: "Based in Los Angeles, California, at the heart of the music industry.",
        foundingDate: "Founded in 2023, by Cory Rose and Andrew Rapier."
    };

    return (
        <div className="about-our-team">
        {/* Company Info Section */}
        <div className="company-info">
            <h1>About YSM.AI</h1>
            <p>{companyInfo.meaning}</p>
            <p>{companyInfo.location}</p>
            <p>{companyInfo.foundingDate}</p>
        </div>

        <div className="about-our-team">
            <h1>Meet Our Team</h1>
            <div className="team-members">
                {teamMembers.map(member => (
                    <div key={member.name} className="team-member">
                        <h2>{member.name}</h2>
                        <h3>{member.title}</h3>
                        <p><strong>Role:</strong> {member.role}</p>
                        <p><strong>Background:</strong> {member.background}</p>
                        <p><strong>Key Contributions:</strong> {member.contributions}</p>
                        <button onClick={() => handleMoreDetails(member)}>More Details</button>
                    </div>
                ))}
            </div>
            {selectedMember && <MemberDetailsPopup member={selectedMember} onClose={() => setSelectedMember(null)} />}
        </div>
        </div>
    );
};

export default AboutOurTeam;
