// TermsConditions.js
import React from 'react';

const TermsConditions = () => {
    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold text-center text-white mb-8">Terms and Conditions</h1>

            <div className="bg-base-200 rounded-lg shadow-lg p-6 mb-8">
                <h2 className="text-2xl font-semibold mb-4">General Usage Terms</h2>
                <p className="mb-4">Welcome to YSM.AI! We are a music generation platform that utilizes copyrighted material from producers, songwriters, and artists to allow users to ethically license and generate music. Please read our Terms and Conditions carefully before using our service.</p>
                <ul className="list-disc pl-5 space-y-2">
                    <li>All users must adhere to the terms and permissions associated with their account. Any manipulation of privileges and permissions is strictly prohibited.</li>
                    <li>Users are granted the right to generate music using our platform within the scope of their subscription and the rights granted therein.</li>
                    <li>Resale or redistribution of music generated on YSM.AI without proper licensing is not allowed.</li>
                    <li>YSM.AI reserves the right to modify, suspend, or discontinue its services at any time without notice.</li>
                    <li>Users must respect copyright laws and ensure they have the necessary rights for any materials they upload or use within the platform.</li>
                    <li>Violation of these terms may result in suspension or termination of your account.</li>
                </ul>
            </div>

            <div className="bg-base-200 rounded-lg shadow-lg p-6 mb-8">
                <h2 className="text-2xl font-semibold mb-4">YSM.AI Privacy Policy</h2>
                <p className="mb-4">Welcome to YSM.AI, a music generation platform operated by UrStudio Corp. We are dedicated to respecting your privacy and safeguarding your personal data. This document outlines our practices regarding data handling, your rights, and our responsibilities.</p>
                
                <h3 className="text-xl font-semibold mb-2">1. Data Collection and Processing</h3>
                <ul className="list-disc pl-5 space-y-2 mb-4">
                    <li><span className="font-semibold">Account Information:</span> We collect information through OAuth by Google and other services, including your name, email address, nickname, and username for account creation and management.</li>
                    <li><span className="font-semibold">Interaction Data:</span> Data related to user interactions, such as beat generation searches, prompting, and engagement with generated music, are collected to enhance user experience and service functionality.</li>
                    <li><span className="font-semibold">Analytics:</span> Generalized analytics are used to measure site performance, track user locations, and analyze retention rates, helping us understand how our services are used and how they can be improved.</li>
                    <li><span className="font-semibold">Uploads and Content:</span> We process data related to any materials you upload or use within the platform, ensuring compliance with copyright laws and the terms of your subscription.</li>
                </ul>

                <h3 className="text-xl font-semibold mb-2">2. Data Usage</h3>
                <ul className="list-disc pl-5 space-y-2 mb-4">
                    <li>Data collected is primarily used to provide and improve our services, facilitate account management, and enhance user interaction with our platform.</li>
                    <li>We may use certain user data for targeted advertising and marketing on platforms like Meta, Google, and others, ensuring content relevance and effective promotion.</li>
                </ul>

                <h3 className="text-xl font-semibold mb-2">3. Data Sharing and Disclosure</h3>
                <ul className="list-disc pl-5 space-y-2 mb-4">
                    <li>We do not share personal data with third-party partners or advertisers, except for necessary disclosures to Streaming Platforms or Copyright and Publishing holders.</li>
                    <li>Any data sharing is conducted in strict adherence to legal requirements and with the utmost respect for user privacy.</li>
                </ul>

                <h3 className="text-xl font-semibold mb-2">4. User Rights and Responsibilities</h3>
                <ul className="list-disc pl-5 space-y-2 mb-4">
                    <li>Users have the right to terminate their account at any time. However, content generated on YSM.AI will be retained for legal and operational reasons, including copyright and publishing compliance.</li>
                    <li>Users are expected to respect copyright laws and adhere to the terms and permissions associated with their account and subscription.</li>
                </ul>

                <h3 className="text-xl font-semibold mb-2">5. International Data Transfer</h3>
                <ul className="list-disc pl-5 space-y-2 mb-4">
                    <li>As an international platform, data may be transferred across borders. We ensure such transfers comply with relevant data protection laws and maintain data security.</li>
                </ul>

                <h3 className="text-xl font-semibold mb-2">6. Security Measures</h3>
                <ul className="list-disc pl-5 space-y-2 mb-4">
                    <li>All interactions with YSM.AI are encrypted, and data is securely stored on our servers. We do not store sensitive information like passwords or payment details.</li>
                    <li>Security measures are continually reviewed and updated to protect against unauthorized access, alteration, or destruction of your personal information.</li>
                </ul>

                <h3 className="text-xl font-semibold mb-2">7. Policy Updates</h3>
                <ul className="list-disc pl-5 space-y-2 mb-4">
                    <li>We reserve the right to update this privacy policy. Changes will be communicated to users, maintaining transparency and compliance with legal standards.</li>
                </ul>

                <h3 className="text-xl font-semibold mb-2">8. Contact Information</h3>
                <ul className="list-disc pl-5 space-y-2 mb-4">
                    <li>For privacy-related inquiries, users can contact us via our contact form or email. We are committed to addressing your concerns promptly and effectively.</li>
                </ul>

                <p className="mt-4">By using our services at YSM.AI, you agree to abide by these privacy terms. We value your trust and are committed to protecting your personal data.</p>
                <p className="mt-2">Thank you for choosing YSM.AI!</p>
            </div>
        </div>
    );
};

export default TermsConditions;
