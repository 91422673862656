import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { authFetch } from './authFetch';
import Notifications from './components/Notifications';
import { displayError, displaySuccess } from './components/utils';

const SubscriptionOptions = () => {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    const [userSubscriptionLevel, setUserSubscriptionLevel] = useState(null);
    const [errorMessages, setErrorMessages] = useState('');
    const [successMessages, setSuccessMessages] = useState('');

    const showError = displayError(setErrorMessages);
    const showSuccess = displaySuccess(setSuccessMessages);

    // These are testing prices, make sure to replace them with live prices
    const subscriptionTiers = [
        {
            name: '(Early Access) Premium',
            price_id: 'price_1OfwE2L5xc0VRk2celX7te9m',
            price: '$7.50',
            description: 'Early adopter discount!',
            tokens: 'Get 100 tokens a month! (Limited time offer)',
            imageUrl: '/Assets/standardcoin.png',
            levelId: 2
        },
        {
            name: 'Premium',
            price_id: 'price_1OfwETL5xc0VRk2cdrHdMmP8',
            price: '$20.00',
            description: 'USD / month.',
            tokens: 'Get 100 tokens a month!',
            imageUrl: '/Assets/standardcoin.png',
            levelId: 3
        },
        {
            name: 'Enterprise',
            price_id: 'price_1OfwGYL5xc0VRk2cfcfSBNFZ',
            price: '$500.00',
            description: 'USD / month.',
            tokens: 'Get 500 tokens a month!',
            imageUrl: '/Assets/enterprise.png',
            levelId: 4
        }
    ];

    useEffect(() => {
        const fetchSubscriptionLevel = async () => {
            try {
                const url = `${process.env.REACT_APP_BACKEND_URL}/get_subscription_level`;
                const data = await authFetch(url, {}, getAccessTokenSilently);
                setUserSubscriptionLevel(data.subscription_level);
                console.log("User subscription level:", data.subscription_level);
            } catch (error) {
                console.error('Error fetching subscription level:', error);
                showError('Failed to fetch subscription level');
            }
        };

        if (isAuthenticated) {
            fetchSubscriptionLevel();
        }
    }, [isAuthenticated, getAccessTokenSilently]);

    const handleSubscriptionSelect = async (tier) => {
        console.log("Selected tier:", tier.levelId);
        console.log("Current user subscription level:", userSubscriptionLevel);

        if (!isAuthenticated) {
            showError('You must be logged in to subscribe.');
            return;
        }

        if (tier.levelId === userSubscriptionLevel) {
            console.log("User already subscribed to this plan");
            showError('You are already subscribed to this plan.');
            return;
        }

        try {
            const idToken = await getAccessTokenSilently();

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/subscription-checkout-session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
                credentials: 'include',
                body: JSON.stringify({ price_id: tier.price_id })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const session = await response.json();
            window.location.href = session.checkoutUrl;
        } catch (error) {
            console.error('There was an issue:', error);
            showError('Failed to initiate subscription process');
        }
    };

    return (
        <div className="subscription-options">
            <div className="mb-4"> {/* Add margin to separate notifications from options */}
                <Notifications
                    errorMessages={errorMessages}
                    successMessages={successMessages}
                    setErrorMessages={setErrorMessages}
                    setSuccessMessages={setSuccessMessages}
                />
            </div>
            {/* For debugging: */}
            {errorMessages && <div className="bg-red-500 text-white p-2 mb-4">{errorMessages}</div>}
            {subscriptionTiers.map((tier, index) => (
                <div key={index} className="subscription-tier">
                    <h3>{tier.name}</h3>
                    <img src={tier.imageUrl} alt={`${tier.name}`} className="subscription-tier-image"/>
                    <p>{tier.price} USD per month</p>
                    <p>{tier.description}<br />{tier.tokens}</p> <br/>
                    <button 
                        onClick={() => handleSubscriptionSelect(tier)}
                        disabled={tier.levelId === userSubscriptionLevel}
                        className={tier.levelId === userSubscriptionLevel ? 'btn-disabled' : 'btn-primary'}
                    >
                        {tier.levelId === userSubscriptionLevel ? 'Current Plan' : 'Subscribe'}
                    </button>
                </div>
            ))}
        </div>
    );
};

export default SubscriptionOptions;
