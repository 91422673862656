// authFetch.js
export const authFetch = async (url, options = {}, getAccessTokenSilently) => {
    try {
        const token = await getAccessTokenSilently();
        const defaultHeaders = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        };

        // Merge provided options with default headers
        const fetchOptions = {
            ...options,
            headers: {
                ...defaultHeaders,
                ...options.headers,  // Allow overriding headers if needed
            },
            credentials: 'include',  // Include credentials by default
        };

        const response = await fetch(url, fetchOptions);

        if (!response.ok) {
            // Attach status code to the error
            const error = new Error(`Network response was not ok: ${response.statusText}`);
            error.status = response.status;
            throw error;
        }

        return await response.json();
    } catch (error) {
        console.error('Error with authenticated fetch:', error);
        throw error;
    }
};
