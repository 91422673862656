import React from 'react';
import './components/RightsAndLicensing.css'; // Link to the CSS file

const RightsAndLicensing = () => {
    return (
        <div className="rights-licensing">
            <h1>Rights & Usage of Music</h1>
            <div className="info-bubble">
                <p>All generated music files are stored, recorded, and tracked for auditing and security purposes of source material creators.</p>
            </div>
            <div className="license-types-row">
            <div className="license-type">
                <h2>Free Downloads</h2>
                <ul>
                    <li>User may download and listen to generated music for personal use. No rights for distribution, ownership, or profit-making.</li>
                    <li>No access to stems or individual files of the generated music.</li>
                    <li>Commercial usage or reproduction is subject to legal action.</li>
                    <li>Similar to an iTunes purchase: personal listening is allowed, but online posting or commercial use is prohibited.</li>
                </ul>
            </div>

            <div className="license-type">
                <h2>Standard Lease</h2>
                <ul>
                    <li>Access to the master file, without ownership.</li>
                    <li>Commercial use is permitted under the terms and conditions of the original creators.</li>
                    <li>Non-exclusive rights; similar melodic elements may be used by others.</li>
                    <li>Right to distribute and earn revenue, but no ownership of source material.</li>
                    <li>Subject to audits and legal action for any violation of terms.</li>
                </ul>
            </div>

            <div className="license-type advance">
                <h2>Advance Lease</h2>
                <ul>
                    <li>Access to the master file, without ownership.</li>
                    <li>Access to the stems of the generated beat.</li>
                    <li>Commercial use is permitted under the terms and conditions of the original creators.</li>
                    <li>Non-exclusive rights; similar melodic elements may be used by others.</li>
                    <li>Right to distribute and earn revenue, but no ownership of source material.</li>
                    <li>Subject to audits and legal action for any violation of terms.</li>
                </ul>
            </div>

            <div className="license-type exclusive">
                <h2>Exclusive Ownership</h2>
                <ul>
                    <li>Grants exclusive rights and shared ownership with original creators.</li>
                    <li>Access to the stems of the generated beat.</li>
                    <li>Permits commercial distribution and profit-making, with agreed returns and splits.</li>
                    <li>Ideal for record labels and major companies for compliance and quality assurance.</li>
                    <li>Reproduction and alterations subject to the discretion of original creators.</li>
                </ul>
            </div>
            </div>
        </div>
    );
};

export default RightsAndLicensing;
