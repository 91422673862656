// src/components/adminTools/AddTokens.js
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { authFetch } from '../../authFetch';
import { FaTimes } from 'react-icons/fa'; 
import { useAuth0 } from '@auth0/auth0-react'; // Import useAuth0


const AddTokens = ({ isOpen, onClose, subscription, refreshSubscriptions }) => {
    const { getAccessTokenSilently } = useAuth0(); // Get the function from the hook
    const [tokens, setTokens] = useState(subscription ? subscription.tokens_remaining : 0);

    // Update tokens when the subscription prop changes
    useEffect(() => {
        if (subscription) {
            setTokens(subscription.tokens_remaining);
        }
    }, [subscription]);

    const handleSubmit = async () => {
        try {
            const url = `${process.env.REACT_APP_BACKEND_URL}/admin/update-tokens`;
            const body = {
                user_id: subscription.user_id,
                tokens_remaining: tokens
            };
            await authFetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            }, getAccessTokenSilently); // Pass the function
            // Refresh the subscriptions list
            refreshSubscriptions();
            onClose();
        } catch (error) {
            console.error('Error updating tokens:', error);
        }
    };

    if (!isOpen || !subscription) {
        return null;
    }

    return ReactDOM.createPortal(
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="relative bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
                {/* Close Button */}
                <button
                    className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
                    onClick={onClose}
                >
                    <FaTimes size={20} />
                </button>

                {/* Modal Content */}
                <h3 className="font-bold text-lg mb-4">Update Tokens for {subscription.username}</h3>
                <p className="mb-4">
                    Current tokens: {subscription.tokens_remaining}
                </p>
                <div className="form-control mb-4">
                    <label className="label">
                        <span className="label-text">New token amount:</span>
                    </label>
                    <input
                        type="number"
                        className="input input-bordered w-full"
                        value={tokens}
                        onChange={(e) => setTokens(Number(e.target.value))}
                        min="0"
                    />
                </div>
                <div className="flex justify-end">
                    <button className="btn mr-2" onClick={onClose}>Cancel</button>
                    <button className="btn btn-primary" onClick={handleSubmit}>Update</button>
                </div>
            </div>
        </div>,
        document.body
    );
};

export default AddTokens;
