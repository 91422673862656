import React from 'react';
import { Link } from 'react-router-dom';
import './signup.css';

const StripeConnectSuccess = () => {
  return (
    <div className="contributor-setup">
      <h2>Congratulations!</h2>
      <p>You've successfully created an AI contributor account.</p>
      <br />
      <div className="form-group">
        <Link to="/account">
          <button className="btn btn-primary">Go To My Account</button>
        </Link>
      </div>
      
      <div className="form-group">
        <Link to="/contributor-setup">
          <button className="btn btn-secondary">Continue Contributor Setup</button>
        </Link>
      </div>
    </div>
  );
};

export default StripeConnectSuccess;
