import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './signup.css';

const ProducerForm = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    birthDate: '',
    producerName: '',
    stripeAccountId: '',
    proPro: '',
    proProId: '',
    masterRoyaltiesInfo: '',
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate form fields
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    try {
      // Get the idToken
      const idToken = await getAccessTokenSilently();
      const checkResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/check-stripe-account`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        credentials: 'include',
      });
      const checkResponseData = await checkResponse.json();
      if (checkResponseData.hasStripeAccount) {
        // Redirect to the success page if the user has a Stripe account
        window.location.href = '/stripe-connect/success';
        return;
      }
      
      
      // Make API call to submit producer data to the backend
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/prod-signup`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(formData),
      });
      

      // Handle successful response
      const responseData = await response.json();
      console.log(responseData);
      // Redirect to the Stripe Connect onboarding page
      if (responseData.redirect) {
        window.location.href = responseData.redirect;
      }
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };


  const validateForm = () => {
    const errors = {};
    // Validate first name
    if (!/^[a-zA-Z]+$/.test(formData.firstName)) {
      errors.firstName = 'First name should only contain letters';
    }
    // Validate last name
    if (!/^[a-zA-Z]+$/.test(formData.lastName)) {
      errors.lastName = 'Last name should only contain letters';
    }
    // Validate birth date
    const currentDate = new Date();
    const selectedDate = new Date(formData.birthDate);
    const age = currentDate.getFullYear() - selectedDate.getFullYear();
    if (age < 18) {
      errors.birthDate = 'You must be at least 18 years old';
    }
    return errors;
  };


  return (
    <div className="signup-container">
      <h2>Producer Registration</h2>
      <p>Enter your details below to sign up as a producer. This will help us manage your royalty payouts and security.</p>
      <br />
      <form onSubmit={handleSubmit}>
      <div className="form-group">
          <label htmlFor="firstName">* First Name:</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            placeholder='Enter your first name'
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="lastName">* Last Name:</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            placeholder='Enter your last name'
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">* Email:</label>
          <input
            type="email"
            id="producerEmail"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder='Enter your email for contact with purchasers'
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="birthDate">* Birth Date:</label>
          <input
            type="date"
            id="birthDate"
            name="birthDate"
            value={formData.birthDate}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="producerName">* Producer Name:
          <span className="info-icon" title="Enter your professional producer name.">
              &#9432;
            </span></label>
          <input
            type="text"
            id="producerName"
            name="producerName"
            value={formData.producerName}
            onChange={handleChange}
            placeholder='Enter your producer name, or real name if preferred'
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="proPro">Producer PRO:
            <span className="info-icon" title="Select your Performing Rights Organization. (BMI/ASCAP/SESAC)">
              &#9432;
            </span>
          </label>
          <select
            id="proPro"
            name="proPro"
            value={formData.proPro}
            onChange={handleChange}
            placeholder='Select your PRO'
          >
            <option value="">Select PRO</option>
            <option value="BMI">BMI</option>
            <option value="ASCAP">ASCAP</option>
            <option value="SESAC">SESAC</option>
            <option value="TONO">TONO</option>
            <option value="GEMA">GEMA</option>
            <option value="MCSC">MCSC</option>
            <option value="ECAD">ECAD</option>
            {/* Add other PROs as needed */}
          </select>
        </div>
        <div className="form-group">
        <label htmlFor="proProId">Producer PRO ID:
          <span className="info-icon" title="Enter your PRO ID number, you can find this on your PRO page profile. Commonly known as IPI #">
            &#9432;
          </span>
        </label>
        <input
          type="text"
          id="proProId"
          name="proProId"
          value={formData.proProId}
          onChange={handleChange}
          placeholder="Enter your PRO ID (IPI #)"
        />
      </div>
        <div className="form-group">
          <label htmlFor="masterRoyaltiesInfo">Master Royalties Info:</label>
          <textarea
            id="masterRoyaltiesInfo"
            name="masterRoyaltiesInfo"
            value={formData.masterRoyaltiesInfo}
            onChange={handleChange}
            placeholder='Enter your master royalties information here. For example:
            - Master Royalties: 0-100%
            - If indie purchase, split via DistroKid, TuneCore, etc.'
          ></textarea>
        </div>
        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
  );
};

export default ProducerForm;