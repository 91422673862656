// ExclusiveRightsPopup.js
import React from 'react';

export const ExclusiveRightsPopup = ({
  contractType,
  contractData,
  onClose,
  onSave,
  onContractChange,
}) => {
  const handleChange = (event) => {
    const updatedData = {
      ...contractData,
      [event.target.name]: event.target.value,
    };
    onContractChange(updatedData)
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <h2>{contractType === 'exclabel_terms' ? 'Exclusive Label Terms' : 'Exclusive Indie Terms'}</h2>
        {/* Render form fields for Exclusive Rights */}
        <div className="form-group">
          <label htmlFor="sell_exclusives">Sell Exclusives:</label>
          <select
            id="sell_exclusives"
            name="sell_exclusives"
            value={contractData.sell_exclusives}
            onChange={handleChange}
            className="form-control"
            onClick={(event) => event.stopPropagation()}
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
          </div>
        <button className="btn btn-primary" onClick={onSave}>
          Save
        </button>
        <button className="btn btn-secondary" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default ExclusiveRightsPopup;