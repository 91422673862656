// src/components/fetchLeaseOptions.js
const fetchLeaseOptions = async (beatId) => {

    try {

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/beat-prods/${beatId}`);
        const data = await response.json();

        const formatPrice = (price) => {
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price);
        };

        return [
            { title: "Standard Lease", details: `MP3 + WAV files, 10000 streams, Price: ${formatPrice(data.standard)}`, price_id: 'standard' },
            { title: "Advanced Lease", details: `MP3 + WAV + Stems, 50000 streams, Price: ${formatPrice(data.advanced)}`, price_id: 'advanced' },
            { title: "Exclusive Rights (Indie)", details: `MP3 + WAV + Stems, unlimited streams, Price: ${formatPrice(data.excindie)}`, price_id: 'excindie' },
            { title: "Exclusive Rights (Label)", details: `MP3 + WAV + Stems, unlimited streams, Price: ${formatPrice(data.exclabel)}`, price_id: 'exclabel' },
        ];
    } catch (error) {
        console.error("Error fetching lease options:", error);
        return [];
    }
};

export default fetchLeaseOptions;
