// src/components/popups/LoadingPopup.js
import React, { useState, useEffect } from 'react';

const LoadingPopup = ({ isOpen }) => {
  const [textIndex, setTextIndex] = useState(0);
  const loadingTexts = [
    "Hold tight...",
    "Checking your music files...",
    "Processing your music into our model...",
    "Almost there...",
    "Tuning the perfect pitch..."
  ];

  useEffect(() => {
    if (isOpen) {
      const interval = setInterval(() => {
        setTextIndex((prevIndex) => (prevIndex + 1) % loadingTexts.length);
      }, 3000); // Change text every 3 seconds

      return () => clearInterval(interval);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-70">
      <div className="modal-box relative bg-white dark:bg-gray-800 text-black dark:text-white p-8 rounded-lg shadow-xl max-w-md w-full">
        <h3 className="font-bold text-2xl mb-4 text-center">Uploading in Progress</h3>
        <p className="mb-6 text-center text-gray-600 dark:text-gray-300">Please wait while your files are being uploaded...</p>
        <div className="loading-animation mb-6 flex justify-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-500"></div>
        </div>
        <p className="text-center text-lg font-medium text-blue-600 dark:text-blue-400 h-8">
          {loadingTexts[textIndex]}
        </p>
      </div>
    </div>
  );
};

export default LoadingPopup;