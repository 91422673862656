// structureUtils.js
export const getFullStructureName = (abbreviation) => {
    const structureTypes = {
        C: 'Chorus',
        V: 'Verse',
        B: 'Bridge',
        I: 'Intro',
        O: 'Outro' // Add more as needed
    };

    return structureTypes[abbreviation] || abbreviation;
};
