import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import genreData from './CollectionSet.json';

const DataSet = () => {
  const [selectedGenre, setSelectedGenre] = useState(null);
  const [showContributions, setShowContributions] = useState(false);
  const [expandedGenre, setExpandedGenre] = useState(null);
  const [isContributionsExpanded, setIsContributionsExpanded] = useState(false);

  const totalValue = genreData.reduce((acc, genre) => acc + genre.value, 0);

  const data = {
    labels: genreData.map((genre) => genre.name),
    datasets: [
      {
        data: genreData.map((genre) => genre.value),
        backgroundColor: [
          '#4F46E5', '#EC4899', '#F59E0B', '#10B981', '#EF4444', 
          '#8B5CF6', '#3B82F6', '#F472B6', '#6366F1', '#A3E635', 
          '#F87171', '#1D4ED8', '#10B981', '#F59E0B', '#9333EA', 
          '#C084FC', '#EA580C', '#6B7280', '#F43F5E', '#D97706'
        ],
        borderColor: [
          '#1E3A8A', '#BE185D', '#B45309', '#065F46', '#991B1B', 
          '#6D28D9', '#1D4ED8', '#DB2777', '#4C1D95', '#65A30D', 
          '#991B1B', '#1E40AF', '#065F46', '#B45309', '#6D28D9', 
          '#7C3AED', '#EA580C', '#4B5563', '#E11D48', '#B45309'
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        onClick: (e, legendItem) => {
          const index = legendItem.index;
          setSelectedGenre(genreData[index]);
        },
        labels: {
          color: 'white',
        },
      },
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw}%`;
          }
        }
      }
    },
    onClick: (e, elements) => {
      if (elements.length > 0) {
        const index = elements[0].index;
        setSelectedGenre(genreData[index]);
      }
    }
  };

  const lowContributions = genreData
    .filter(genre => (genre.value / totalValue) * 100 < 7)
    .sort((a, b) => (b.value / totalValue) - (a.value / totalValue));

  const getBackgroundColor = (percentage) => {
    if (percentage < 1) return 'bg-red-800';
    if (percentage < 2) return 'bg-red-700';
    if (percentage < 3) return 'bg-red-600';
    if (percentage < 4) return 'bg-red-500';
    if (percentage < 5) return 'bg-red-400';
    if (percentage < 6) return 'bg-yellow-600';
    return 'bg-yellow-400';
  };

  const toggleContributions = () => {
    setShowContributions(!showContributions);
    setExpandedGenre(null);
  };

  const toggleGenreExpansion = (genreName) => {
    setExpandedGenre(expandedGenre === genreName ? null : genreName);
  };
  return (
    <div className="container mx-auto py-6 text-white max-w-3xl">
      <h1 className="text-4xl font-bold mb-4 text-center">Our Dataset</h1>
      <p className="mb-6 text-center">Look inside our current dataset to see what's possible with our platform.</p>
      
      {/* Pie Chart */}
      <div className="flex justify-center mb-6">
        <div style={{ width: '100%', maxWidth: '500px', height: 'auto' }}>
          <Pie data={data} options={options} />
        </div>
      </div>

      {/* Genre Information */}
      {selectedGenre && (
        <div className="mt-4 border rounded p-4 bg-gray-800 text-white">
          <h2 className="text-2xl font-semibold">{selectedGenre.name} ({((selectedGenre.value / totalValue) * 100).toFixed(2)}%)</h2>
          <h3 className="text-xl mt-2">{selectedGenre.style}, {selectedGenre.style} Style Artists & Genres</h3>
          <p className="mt-4">
            This genre and style covers artists, instruments and moods of {selectedGenre.name}. Some examples include:
          </p>
          <ul className="list-disc list-inside mt-2">
            {selectedGenre.artists.map((artist) => (
              <li key={artist}>{artist}</li>
            ))}
          </ul>
          <p className="mt-4">
            Bullet point lists of {selectedGenre.name}
          </p>
        </div>
      )}

      {/* Low Contributions Section */}
      <div className="mt-12">
        <h2 className="text-3xl font-bold mb-4 text-center">Genres Needing Contributions</h2>
        <button 
  onClick={() => setIsContributionsExpanded(!isContributionsExpanded)}
  className="mb-4 py-2 px-4 bg-blue-600 text-white rounded hover:bg-blue-700 mx-auto block transition-all duration-300 ease-in-out"
>
  {isContributionsExpanded ? 'Hide Contributions' : 'Show Contributions'}
</button>
        
        <div
          className={`overflow-hidden transition-all duration-500 ease-in-out ${isContributionsExpanded ? 'opacity-100 max-h-screen' : 'opacity-0 max-h-0'}`}
        >
          <div className="space-y-4">
            {lowContributions.map(genre => {
              const percentage = ((genre.value / totalValue) * 100).toFixed(2);
              const bgColor = getBackgroundColor(percentage);
              const isExpanded = expandedGenre === genre.name;

              return (
                <div 
                  key={genre.name} 
                  className={`
                    rounded text-white ${bgColor} cursor-pointer
                    transition-all duration-300 ease-in-out
                    hover:shadow-lg
                    ${isExpanded ? 'shadow-lg' : ''}
                  `}
                  onClick={() => toggleGenreExpansion(genre.name)}
                >
                  <div className="p-4">
                    <h3 className="text-xl font-semibold">{genre.name} ({percentage}%)</h3>
                  </div>
                  <div 
                    className={`
                      overflow-hidden transition-all duration-300 ease-in-out
                      ${isExpanded ? 'max-h-40' : 'max-h-0'}
                    `}
                  >
                    <div className="p-4 pt-0">
                      <p className="mt-2">Style: {genre.style}</p>
                      <p className="mt-1">Artists: {genre.artists.join(', ')}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Back to Home Button */}
      <button className="mt-8 py-2 px-6 bg-blue-800 text-white rounded-lg hover:bg-blue-700 mx-auto block">
        <Link to="/">Back to Home</Link>
      </button>
    </div>
  );
};

export default DataSet;