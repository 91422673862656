import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { authFetch } from '../authFetch'; // Import your utility
import { useAuth0 } from '@auth0/auth0-react';

const SubscriptionSuccess = () => {
    const [sessionData, setSessionData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { getAccessTokenSilently } = useAuth0();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const session_id = queryParams.get('session_id');

    useEffect(() => {
        const fetchSessionDetails = async () => {
            try {
                const url = `${process.env.REACT_APP_BACKEND_URL}/get-session-details/${session_id}`;
                const data = await authFetch(url, {}, getAccessTokenSilently);
                setSessionData(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        if (session_id) {
            fetchSessionDetails();
        }
    }, [session_id, getAccessTokenSilently]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    // Extract important details
    const { customer_details, amount_total, currency, payment_status, subscription } = sessionData || {};

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold text-center text-white mb-8">Success!</h1>
            <div className="flex items-center justify-center mb-8">

                <p className="text-3xl font-bold text-center text-white bg-green-500 px-4 py-2 rounded-lg shadow-lg">Your payment was successful!</p>
            </div>

            {sessionData && (
                <div className="bg-gray-100 rounded-lg shadow-lg p-6 mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Session Details</h2>

                    {/* Customer details */}
                    <div className="mb-4">
                        <p><strong>Customer Name:</strong> {customer_details?.name}</p>
                        <p><strong>Customer Email:</strong> {customer_details?.email}</p>
                    </div>

                    {/* Payment details */}
                    <div className="mb-4">
                        <p><strong>Amount Paid:</strong> ${(amount_total / 100).toFixed(2)} {currency?.toUpperCase()}</p>
                        <p><strong>Payment Status:</strong> {payment_status}</p>
                    </div>

                    {/* Subscription ID */}
                    <div className="mb-4">
                        <p><strong>Subscription ID:</strong> {subscription}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SubscriptionSuccess;
