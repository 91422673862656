export const displayError = (setErrorMessages) => (message) => {
    setErrorMessages(message);
    setTimeout(() => {
      const errorMessageElement = document.querySelector('.errmsg');
      if (errorMessageElement) {
        errorMessageElement.classList.add('errmsg-fadeout');
      }
      setTimeout(() => setErrorMessages(''), 1500); // Wait for fade-out to complete
    }, 2000);
  };
  
  export const displaySuccess = (setSuccessMessages) => (message) => {
    setSuccessMessages(message);
    setTimeout(() => {
      const successMessageElement = document.querySelector('.successmsg');
      if (successMessageElement) {
        successMessageElement.classList.add('successmsg-fadeout');
      }
      setTimeout(() => setSuccessMessages(''), 1500); // Wait for fade-out to complete
    }, 2000);
  };